import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Page from "./component/page";
import {
  aboutPattern,
  categoryIDPattern,
  categoryPattern,
  categoryProductPattern,
  contactPattern,
  errorPattern,
  homePattern,
  layoutPattern,
  policyPattern,
  tourPattern,
} from "./routes";
import Loading from "./screens/loading";
import "react-responsive-modal/styles.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import AOS from "aos";
import "aos/dist/aos.css";
import PageClient from "./component/pageClient";
import { ToastProvider } from "react-toast-notifications";

const Home = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/home"),
    new Promise((resolve) => setTimeout(resolve, 1000)),
  ]);
  return moduleExports;
});

const TourUI = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/tourUI"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]);
  return moduleExports;
});

const LayoutTour = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/layoutTour"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]);
  return moduleExports;
});

const About = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/about"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]);
  return moduleExports;
});

const Contact = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/contact"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]);
  return moduleExports;
});

const Category = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/category"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]);
  return moduleExports;
});

const CategoryProduct = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/categoryProduct"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]);
  return moduleExports;
});

const CategoryProductDetails = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/categoryProductDetails"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]);
  return moduleExports;
});

const Error404 = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/404"),
    new Promise((resolve) => setTimeout(resolve, 0)),
  ]);
  return moduleExports;
});

const Privacy = lazy(async () => {
  const [moduleExports] = await Promise.all([
    import("./screens/privacy"),
    new Promise((resolve) => setTimeout(resolve, 0)),
  ]);
  return moduleExports;
});

function App() {
  AOS.init({
    once: true,
    easing: "ease-in-out",
  });

  return (
    <ToastProvider newestOnTop autoDismiss autoDismissTimeout={2000}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            exact
            path={errorPattern}
            element={
              <Page>
                {" "}
                <Error404 />
              </Page>
            }
          />

          <Route
            path={homePattern}
            element={
              <Page>
                <Home />
              </Page>
            }
          />

          <Route
            exact
            path={contactPattern}
            element={
              <Page>
                <Contact />
              </Page>
            }
          />

          <Route
            exact
            path={aboutPattern}
            element={
              <Page>
                <About />
              </Page>
            }
          />

          <Route
            exact
            path={policyPattern}
            element={
              <Page>
                <Privacy />
              </Page>
            }
          />

          <Route
            exact
            path={categoryPattern}
            element={
              <PageClient>
                <Category />
              </PageClient>
            }
          />

          <Route
            path={layoutPattern}
            element={
              // <PageClient>
              <LayoutTour/>
              //  </PageClient>
            }
          />

          <Route
            path={tourPattern}
            element={
              // <PageClient>
              <TourUI />
              // </PageClient>
            }
          />

          <Route
            path={categoryIDPattern}
            element={
              <PageClient>
                <CategoryProduct />
              </PageClient>
            }
          />
          <Route
            path={categoryProductPattern}
            element={
              <PageClient>
                <CategoryProductDetails />
              </PageClient>
            }
          />
        </Routes>
      </Suspense>
    </ToastProvider>
  );
}

export default App;
